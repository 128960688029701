import ColumnsResize from "columns-resize";
import {Visualizer} from './Visualizer.js';
import {setFaviconForTheme} from './favicon.js';

setFaviconForTheme();

const visualizer = new Visualizer();

const instance = new ColumnsResize(document.getElementById("main-container"), {
    minWidthByColumnId: {
        three: 275,
        chat: 320,
        code: 450,
    },
    autoResizeHandles: true,
    onResizeStart: () => {
        updateSize();
        updateUI();
    },
    logs: false,
});

let active_polling = false;
let project_key = "";
let project_id = 0;
let uiState = {
  worker_state: "FINISHED",
  chat_blocked: false,
  viewport_blocked: false,
};
let previousUIState = {};
let message_log = [];

function updateUI() {
    if (JSON.stringify(uiState) === JSON.stringify(previousUIState)){
        return;
    }

    const disableControls = (disable) => {
        const controls = [fileUploader, btnRun, btnVerify, messageInput];
        controls.forEach(control => control.disabled = disable);
        codeEditor.setOption("readOnly", disable);
        visualizer.send_selection_enabled = !disable;
        if (!disable) {
            messageInput.focus();
        }
        document.querySelector('.input-form button[type="submit"]').disabled = disable;
    };

    const displayElement = (element, displayStyle) => {
        element.style.display = displayStyle;
    };

    // Reset states
    displayElement(preloader_chat, uiState.chat_blocked ? "block" : "none");
    disableControls(uiState.chat_blocked || uiState.worker_state === "RUNNING" || uiState.worker_state === "TERMINATING");
    displayElement(preloader_viewport, uiState.viewport_blocked ? "flex" : "none");
    displayElement(preloader_simulation, "none");
    displayElement(btnCancel, "none");
    btnCancel.disabled = false;
    switch (uiState.worker_state) {
        case "RUNNING":
            displayElement(preloader_simulation, "inline-block");
            displayElement(btnCancel, "inline-block");
            break;
        case "FINISHED":
            break;
        case "ERROR":
            break;
        case "TERMINATING":
            btnCancel.disabled = true;
            displayElement(btnCancel, "inline-block");
            break;
    }

    previousUIState = JSON.parse(JSON.stringify(uiState));
}

async function showFile() {
    const maxRetries = 300;
    const retryDelay = 1000;

    const token = generateSignedToken();
    const baseUrl = `${window.location.protocol}//${window.location.host}`;
    const url = baseUrl + '/shown_file';
    uiState.viewport_blocked = true;
    for (let retries = 0; retries < maxRetries; retries++) {
        const response = await fetch(url, {
            method: 'GET',
            headers: {
                'Authorization': project_id + ' Bearer ' + token,
                'Geometry-Hash': visualizer._geometryHash
            },
        });

        if (response.status === 200) {
            const result = await response.json();
            visualizer.update_show_file(result);
            uiState.viewport_blocked = false;
            updateUI();
            break;
        } else if (response.status === 202) {
            uiState.viewport_blocked = false;
            updateUI();
            break;
        }  else if (response.status === 201) {
            await new Promise(resolve => setTimeout(resolve, retryDelay));
        } else if (response.status === 400) {
            console.error('Error: Invalid project file');
            project_key = "";
            updateUI();
            errorMessage.textContent = "Your project file is not valid";
            loginModalInstance.open();
            break;
        } else if (!response.ok) {
            throw new Error(`Server responded with an error: ${response.status}`);
        }

        if (retries === maxRetries - 1) {
            throw new Error('Max retries reached, server is still processing the file.');
        }
    }
}



function displayMessageLog(messageLog) {
    messagesDiv.textContent = "";
    messageLog.forEach((message) => {
        let message_prefix = '';
        if (message.content !== "") {
            const messageElement = document.createElement("div");
            addMsgIcon(messageElement, message.action, message.role);
            if (message.role === 'user') {
                if (message.action === 'NONE') {
                    messageElement.classList.add('message', 'message__user');
                }
                else {
                    messageElement.classList.add('message', 'message__user--action');
                }
            }

            else if (message.role === 'assistant') {
                messageElement.classList.add('message', 'message__assistant');
            }

            else if (message.role === 'terminal') {
                messageElement.classList.add('message', 'message__assistant');
    }

            const preElement = document.createElement("pre");
         
            preElement.textContent = message_prefix + message.content;
            messageElement.appendChild(preElement);

            messagesDiv.insertBefore(messageElement, messagesDiv.firstChild);
        }
    });

    messagesDiv.scrollTop = messagesDiv.scrollHeight;
}

async function updateState() {
    if (project_key) {
        logoutLoginBtn.textContent = "Close Project";
        mainContainer.style.display = "flex";

        const response = await fetch(`/state`, {
            method: 'GET',
            headers: {
                'Authorization': project_id + ` Bearer ${generateSignedToken()}`,
                'language': navigator.language || 'en-US'
            }
        });
        if (response.ok) {
            const data = await response.json();

            if (data.code) {
                let displayed_code = codeEditor.getValue();
                if (displayed_code !== data.code) {
                    codeEditor.setValue(data.code);
                }
            }

            if (JSON.stringify(message_log) !== JSON.stringify(data.message_log)) {
                message_log = data.message_log;
                displayMessageLog(message_log);

                const replyMessage = await signAndSendHTTP('/process_actions', {});
                if (replyMessage === 'Action Performed') {
                    await updateState();
                    return
                }
            }
            uiState.worker_state = data.worker_state;
            uiState.chat_blocked = data.chat_blocked;
            uiState.viewport_blocked = data.viewport_blocked;

            active_polling = uiState.chat_blocked || uiState.viewport_blocked ||
                uiState.worker_state === "RUNNING" || uiState.worker_state === "TERMINATING";

            updateUI();
            showFile().then(); // ToDo use async
        } else {
            project_key = "";
            errorMessage.textContent = "Invalid project key. Please enter a valid key.";
            loginModalInstance.open();
        }
    } else if (!project_key) {
        logoutLoginBtn.textContent = "Open Project";
        mainContainer.style.display = "none";
    }
}

function generateSignedToken() {
    const header = { alg: "RS256", typ: "JWT" };

    const payload = {
        project_id: project_id,
        exp: Math.floor(Date.now() / 1000) + (60 * 60),
        iat: Math.floor(Date.now() / 1000),
    };

    return KJUR.jws.JWS.sign(
        null,
        JSON.stringify(header),
        JSON.stringify(payload),
        project_key
    );
}

export async function signAndSendHTTP(endpoint, payload) {
    try {
        const baseUrl = `${window.location.protocol}//${window.location.host}`;
        const url = baseUrl + endpoint;
        const token = generateSignedToken();

        const response = await fetch(url, {
            method: 'POST',
            headers: {
                'Authorization': project_id +  ' Bearer ' + token,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(payload)
        });

        if (response.status === 400) {
            console.error('Error 400: Bad Request');
            project_key = "";
            updateState();
            errorMessage.textContent = "Your project file is not valid";
            loginModalInstance.open();
            return response.statusText;
        }

        if (!response.ok) {
            throw new Error(`Server responded with an error: ${response.statusText}`);
        }

        const responseData = await response.text(); // Assuming the response is in text format
        return responseData;

    } catch (error) {
        console.log(error);
        project_key = "";
        updateState();
        errorMessage.textContent = "Invalid project key. Please enter a valid key.";
        loginModalInstance.open();
        return error.message;
    }
}


async function signAndSendFile(url, file = null, screenshot) {
    try {
        const token = generateSignedToken();
        const formData = new FormData();

        if (file) {
            formData.append('file', file, file.name);
        }

        const code = codeEditor.getValue();
        formData.append('code', code);

        const headers = {
            'Authorization': project_id + ' Bearer ' + token,
        }

        if (screenshot) {
            headers['Screenshot'] = 'screenshot';
        }

        const baseUrl = `${window.location.protocol}//${window.location.host}`;
        const response = await fetch(baseUrl + '/upload', {
            method: 'POST',
            body: formData,
            headers: headers
        });

        if (!response.ok) {
            throw new Error('Server responded with an error');
        }

        await response.json();
        return true;
    } catch (error) {
        console.error(error);
        project_key = "";
        updateState();
        errorMessage.textContent = "Invalid project key. Please enter a valid key.";
        loginModalInstance.open();
        return false;
    }
}


function readFileAsText(file, callback) {
    const reader = new FileReader();
    reader.onload = () => {
        callback(reader.result);
    };
    reader.onerror = () => {
        console.error(reader.error);
    };
    reader.readAsText(file);
}

function generateScreenshotFilename() {
    const now = new Date();

    const pad = (num) => num.toString().padStart(2, '0');

    const month = pad(now.getMonth() + 1); // Month in JS in 0 based
    const day = pad(now.getDate());
    const year = now.getFullYear();

    const hours = pad(now.getHours());
    const minutes = pad(now.getMinutes());
    const seconds = pad(now.getSeconds());

    return `screenshot_${month}_${day}_${year}_${hours}_${minutes}_${seconds}.png`;
}

function addMsgIcon(container, type, role) {
    const img = document.createElement('img');
    if (type === 'UPLOAD_FILE') {
        img.src = `${import.meta.env.VITE_ASSETS_SVG_PATH}arrow_dark.svg`;
        img.classList.add('msgUp');
        container.appendChild(img.cloneNode(true));
    } else if (type === 'SELECT_GEOMETRY') {
        img.src = `${import.meta.env.VITE_ASSETS_SVG_PATH}arrow_dark.svg`;
        img.classList.add('msgAtAnAngle');
        container.appendChild(img.cloneNode(true));
    }
    else if (type === 'NONE' && role === 'assistant') {
        img.src = `${import.meta.env.VITE_ASSETS_IMG_PATH}drq_logo_light_trimmed.png`;
        img.style.display = "block";
        container.appendChild(img.cloneNode(true));
    }
    else if (type === 'EDIT_CODE') {
        img.src = `${import.meta.env.VITE_ASSETS_IMG_PATH}drq_logo_light_trimmed_edit.png`;
        img.style.display = "block";
        container.appendChild(img.cloneNode(true));
    }
    else if (type === 'SHOW_FILE') {
        img.src = `${import.meta.env.VITE_ASSETS_IMG_PATH}drq_logo_light_trimmed_show.png`;
        img.style.display = "block";
        container.appendChild(img.cloneNode(true));
    }
    else if (type === 'RUN_CODE') {
        img.src = `${import.meta.env.VITE_ASSETS_IMG_PATH}drq_logo_light_trimmed_run.png`;
        img.style.display = "block";
        container.appendChild(img.cloneNode(true));
    }
    else if (type === 'VERIFY_CODE') {
        img.src = `${import.meta.env.VITE_ASSETS_IMG_PATH}drq_logo_light_trimmed_verify.png`;
        img.style.display = "block";
        container.appendChild(img.cloneNode(true));
    }
    else if (type === 'SUPPORT') {
        img.src = `${import.meta.env.VITE_ASSETS_IMG_PATH}drq_logo_light_trimmed_support.png`;
        img.style.display = "block";
        container.appendChild(img.cloneNode(true));
    }
    else if (role === 'terminal') {
        img.src = `${import.meta.env.VITE_ASSETS_IMG_PATH}terminal.png`;
        img.style.display = "block";
        container.appendChild(img.cloneNode(true));
    }
}

//Event listeners
logoutLoginBtn.addEventListener("click", () => {
    if (project_key) {
        project_key = "";
    } else {
        loginModalInstance.open();
    }
    updateState();
});

messageInput.addEventListener("keydown", function (event) {
    if (event.key === "Enter" && !event.shiftKey) {
        event.preventDefault();
        inputForm.dispatchEvent(new Event("submit"));
    }

    // Dynamically resize the textarea
    var limit =
    parseInt(getComputedStyle(messageInput).lineHeight.replace("px", "")) * 4;

    messageInput.style.height = "";
    messageInput.style.height = Math.min(messageInput.scrollHeight, limit) + "px";
});

projectKeyFile.addEventListener("change", () => {
    document.getElementById("submit-key-button").focus();
});

loginForm.addEventListener("submit", (e) => {
    e.preventDefault();

    if (projectKeyFile.files.length === 0) {
        errorMessage.textContent = "Please select a project key file.";
        return;
    }

    const file = projectKeyFile.files[0];
    readFileAsText(file, (fileContent) => {
        const fileData = JSON.parse(fileContent);
        project_key = fileData.key;
        project_id = parseInt(fileData.project, 10);
        projectKeyFile.value = "";
        errorMessage.textContent = "";
        loginModalInstance.close();
        const observer = new MutationObserver((mutations, obs) => {
            const messageInput = document.getElementById('message-input');
            if (messageInput) {
                messageInput.focus();
                obs.disconnect();
            }
        });

        observer.observe(document.body, {
            childList: true,
            subtree: true
        });
        visualizer.setAnimationCallback(animate);
        visualizer.run();
        uiState.worker_state = 'RUNNING';
        uiState.chat_blocked = true;
        uiState.viewport_blocked = true;
        updateUI();
        updateState().then();
    });
});

btnScreenshot.addEventListener('click', async () => {
    try {
        const screenshotDataURL = await visualizer.takeScreenshot(true);
        const byteString = atob(screenshotDataURL.split(',')[1]);
        const mimeString = screenshotDataURL.split(',')[0].split(':')[1].split(';')[0];

        const byteArray = new Uint8Array(byteString.length);
        for (let i = 0; i < byteString.length; i++) {
            byteArray[i] = byteString.charCodeAt(i);
        }

        const blob = new Blob([byteArray], { type: mimeString });
        const file = new File([blob], generateScreenshotFilename(), { type: mimeString });

        await signAndSendFile('upload', file, true);
    } catch (error) {
        console.error('Error processing screenshot:', error);
    }
});

btnRun.addEventListener("click", () => {
    signAndSendHTTP("/run_code", { code: codeEditor.getValue(), verify_only: false }).then(() => {
        updateState();
    });
    uiState.worker_state = 'RUNNING';
    uiState.chat_blocked = true;
    active_polling = true;
    updateUI();
});


btnVerify.addEventListener("click", () => {
    signAndSendHTTP("/run_code", { code: codeEditor.getValue(), verify_only: true }).then(() => {
        updateState();
    });
    uiState.worker_state = 'RUNNING';
    uiState.chat_blocked = true;
    active_polling = true;
    updateUI();
});

btnCancel.addEventListener("click", () => {
    signAndSendHTTP("/cancel_simulation", { }).then(() => {
        updateState();
    });
    uiState.worker_state = 'TERMINATING';
    updateUI();
});

inputForm.addEventListener("submit", (e) => {
    e.preventDefault();
    let msg = messageInput.value;
    message_log.push({'content': msg, 'role': 'user', 'action': 'NONE'});
    const userMessageElement = document.createElement("div");
    userMessageElement.classList.add("message", "user");
    userMessageElement.textContent = msg;
    userMessageElement.classList.add('message', 'message__user');
    messagesDiv.insertBefore(userMessageElement, messagesDiv.firstChild);
    messagesDiv.scrollTop = messagesDiv.scrollHeight;
    displayMessageLog(message_log);
    messageInput.value = '';

    uiState.chat_blocked = true;
    updateUI();
    signAndSendHTTP("/send_message", {
            message: msg,
            code: codeEditor.getValue(),
            action: 'NONE',
            language: navigator.language || 'en-US'}).then(() => {
        active_polling = true;
    });
});

loginModalInstance.el.addEventListener("close", () => {
    projectKeyInput.value = "";
});

btnDownload.addEventListener("click", () => {
    const options = {
        method: 'GET',
        headers: {
            'Authorization': project_id +  ' Bearer ' + generateSignedToken(),
        }
    };

    fetch(`/download_results`, options)
        .then(response => {
            if (response.ok) {
                return response.blob();
            } else {
                return response.text().then(text => { throw new Error(text) });
            }
        })
        .then(blob => {
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.style.display = 'none';
            a.href = url;
            a.download = 'workspace.zip';
            document.body.appendChild(a);
            a.click();
            window.URL.revokeObjectURL(url);
        })
        .catch(error => {
            console.error('Error:', error);
        });
});

window.addEventListener('keydown', function(event) {
    if (event.ctrlKey && event.key === 'r') {
        event.preventDefault(); // Prevent the default refresh behavior
        const userConfirmed = window.confirm("Are you sure you want to reset? ALL DATA WILL BE LOST");
        if (userConfirmed) {
            codeEditor.setValue('');
            uiState.worker_state = 'RUNNING';
            uiState.chat_blocked = true;
            uiState.viewport_blocked = true;
            updateUI();
            signAndSendHTTP("/reset", { }).then(() => {
                updateState().then();
            });
        }
    }
    else if (event.ctrlKey && event.key === 'z') {
        event.preventDefault();
        uiState.worker_state = 'RUNNING';
        uiState.chat_blocked = true;
        uiState.viewport_blocked = true;
        updateUI();
        signAndSendHTTP("/undo", { }).then(() => {
                updateState().then();
            });
    }
});


// Update on resize
window.addEventListener("resize", () => {
    updateSize();
});

// Add an event listener for the form submission
fileUploader.addEventListener('change', async function(event) {
    uiState.chat_blocked = true;
    updateUI();
    if (event.target.files.length > 0) {
        const file = event.target.files[0];
        try {
            await signAndSendFile('upload', file);
        } catch (error) {
            alert('File upload failed: ' + error.message);
        }
        fileUploader.value = '';
    }
    active_polling = true
});

visualizer.on('selection', (selection) => {
    let msg = 'selected surfaces: [' + selection + ']';
    message_log.push({'content': msg, 'role': 'user', 'action': 'SELECT_GEOMETRY'});
    displayMessageLog(message_log);
    messageInput.value = '';
    signAndSendHTTP("/send_message", {
            message: msg,
            code: codeEditor.getValue(),
            action: 'SELECT_GEOMETRY',}).then(() => {
        uiState.chat_blocked = true;
        updateState();
    });
});


function animate()
{
    requestAnimationFrame( animate );
    visualizer.animate();
}

function updateSize()
{
    visualizer.updateSize(updateSize);
}

window.addEventListener("load", () => {
  const loader = document.getElementById("loader");
  loader.style.display = "none";
});

document.addEventListener('DOMContentLoaded', function() {
    const mainNavLogoImg = document.querySelector('.main-nav__logo img');
    mainNavLogoImg.addEventListener('mouseover', function() {
        mainNavLogoImg.src = `${import.meta.env.VITE_ASSETS_IMG_PATH}drq_logo_happy.png`;
    });
    mainNavLogoImg.addEventListener('mouseout', function() {
        mainNavLogoImg.src = `${import.meta.env.VITE_ASSETS_IMG_PATH}drq_logo_light_trimmed.png`;
    });
});

async function state_loop() {
    while (true) {
        if (active_polling) {
            await updateState();
        }
        await new Promise(resolve => setTimeout(resolve, 500));
    }
}

updateUI();
updateSize();

active_polling = true;
state_loop();